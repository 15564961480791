@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;500&display=swap');
 /* @import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400&display=swap'); */

 *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Mulish, serif;  
  scroll-behavior: smooth;
}

.card-title{
font-weight: bold;
color: #02a8b4;
}
/* custom scroll bar */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: #02a8b4;
}

::-webkit-scrollbar-thumb:hover {
  background: #02a8b4;
}

.menu_active{
  font-weight:bold;
  color:#02a8b4 !important;
}

.navbar a:hover{
  color:rgb(3, 177, 177) !important;
  transition: ease 0.2s;
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
    padding-top: 30px;
}
.navbar-nav .nav-item {
  padding-left: 8px;
  padding-right: 8px;
}

.jus {
  text-align: justify;
  font-size: 15px;
}

.brand-name{
  /* color:#9b0404 !important; */
  color:#02a8b4 !important;
}

h1, h3 {
  color:#02a8b4 !important;
}

.animated{
    animation-name: floating;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    /* margin-left: 30px;
    margin-top: 5px; */
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

.btn-primary{
 background: #02a8b4 !important;
 border-radius: 60px !important;
 padding: 10px 20px !important;
 border: none !important;
}

.btn-primary:hover{
  background: #920000 !important;
}

.btn-warning{
  background: #02a8b4 !important;
  border-radius: 60px !important;
  /* padding: 10px 20px !important; */
  font-size: 15px !important;
  border: none !important;
  color: white !important;
 }
 
 .btn-warning:hover,
 .btn-warning:focus {
   background: #920000 !important;
   cursor: pointer !important; 
 }

 .cardbtn {
font-size: 14px!important;
 }

#header, #about, #partners, #contact{
  width: 100%;
  height: 78vh;
  margin-top: 5px;
}

.header-img {
  text-align: right;
}

.text-red {
  color: #920000;
}

.about-img{
  text-align: center;  
}

.fadeAnim {
  opacity: 1;
  animation: fade 1.5s linear;
}

@keyframes fade {
  0% { 
    opacity: 0 
  }
  100% { 
    opacity: 1 
  }
}

.avoid-overflow{
  overflow-x: hidden;
}
/* 
.contact-section{
margin: rem 0;
} */

.contact-leftside img {
  min-width: 70%;
  max-height: 13rem;
  height: auto;
  text-align: center !important;
}

.contact-input-field {
margin-bottom: 40px;
}


#menu-btn {
  color: #02a8b4;
  background: none;
  border: none;
  height: 30px !important;
}

.swal-button {
  background-color: #02a8b4;
}

.swal-button:hover {
  background-color: #027e86;
}

footer{
  font-size: 13px;
  padding: 10px 10px 0px 10px;
  bottom: 0;  
  width: 100%;
  color: #02a8b4;
  text-align: center;
}

footer a, footer a:hover{
  text-decoration: none !important;
  color: #02a8b4;
}
@media screen and (max-width: 960px) {
  footer{
    font-size: 10px;
    text-align: center;  
    position: fixed;
    bottom: 0;
    width: 100%;
    /* margin-top: 30px; */
    padding-bottom: 6px;
    background-color: #02a8b4;
    height: 30px;
    color: #ffffff;
    text-align: center;

  }
  footer a, footer a:hover{
    text-decoration: none !important;
    color: #fff;
  }
}