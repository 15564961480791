.slider {
  height: 12rem;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track-partners {
  display: flex;
  width: calc(12rem * 42);
  animation: scroll 55s linear infinite;
}

.slide-track-partners:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-12rem * 16.2));
  }
}

.slide-partners {
  height: 12rem;
  width: 270px;
  display: flex;
  align-items: center;
  padding: 30px;
  perspective: 100px;
}

.slide-partners a,
.slide-partners img {
  max-width: 100%; 
  height: auto;
  display: block;
  transition: transform 1s;
}

.slide-partners a:hover {
  transform: translateZ(10px);
  cursor: pointer;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

@media (max-width: 1200px) {
  .slide-partners {
    height: 200px;
    width: 10rem;
    display: flex;
    align-items: center;
    padding: 30px;
    perspective: 100px;
  }
 
  .slide-partners a,
  .slide-partners img {
    max-width: 100%; 
    height: auto;
    display: block;
    transition: transform 1s;
  }
 
  .max-width {
    max-width: 930px;
  }
}
 
@media (max-width: 690px) {
  .max-width {
    padding: 0 23px;
  }
}
@media (max-width: 400px) {
  .slide-partners {
    height: 200px;
    width: 10em;
    display: flex;
    align-items: center;
    padding: 30px;
    perspective: 100px;
  }
 
  .slide-partners a,
  .slide-partners img {
    max-width: 100%; 
    height: auto;
    display: block;
    transition: transform 1s;
  }
}